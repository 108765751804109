@font-face {
    font-family: 'Nunito Sans';
    src: url('~src/assets/fonts/Nunito Sans/NunitoSans-ExtraBold.woff2') format('woff2'),
        url('~src/assets/fonts/Nunito Sans/NunitoSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('~src/assets/fonts/Nunito Sans/NunitoSans-SemiBold.woff2') format('woff2'),
        url('~src/assets/fonts/Nunito Sans/NunitoSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('~src/assets/fonts/Nunito Sans/NunitoSans-Light.woff2') format('woff2'),
        url('~src/assets/fonts/Nunito Sans/NunitoSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
